import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { Container, Heading, Stack, Image, Text, Box } from "@chakra-ui/react"
import { AnimeHeading } from "../components/anime-heading"
import { AnimeText } from "../components/anime-text"

const LineFriend = () => {
  const boxVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, y: 50 },
  }
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start("visible")
    } else {
      control.start("hidden")
    }
  }, [control, inView])

  return (
    <Layout>
      <Seo title="お友達登録" />
      <Box
        h={`10vh`}
        backgroundSize="cover"
      >
      </Box>
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack pt={12} pb={36} justify="center" align="center" spacing={6}>
          <AnimeHeading text={`お友達登録`} fontWeight={`bold`} fontSize={24} fontFamily={`inter`} />
          <Image
            src={`https://qr-official.line.me/sid/M/006xeuzz.png?shortenUrl=true`}
            h={64}
            w={64}
          />
          <AnimeText
            text={`ライン友達登録いただいた方に、お得な情報をお届けします`}
            fontSize={16}
            fontFamily={`inter`}
          />
          <Stack
            as={motion.p}
            lineHeight={1.8}
            fontWeight={`normal`}
            variants={boxVariant}
            ref={ref}
            initial={`hidden`}
            animate={control}
            w={`400px`}
            spacing={4}
          >
            <Box
              bg={`blackAlpha.50`}
              px={4}
              pt={3}
              pb={6}
            >
              <Heading
                fontSize={18}
              >
                便利な使い方❶
              </Heading>
              <Text
                mt={4}
                fontSize={14}
              >
                新着の物件情報をいち早くお届けします
              </Text>
            </Box>
            <Box
              bg={`blackAlpha.200`}
              px={4}
              pt={3}
              pb={6}
            >
              <Heading
                fontSize={18}
              >
                便利な使い方❷
              </Heading>
              <Text
                mt={4}
                fontSize={14}
              >
              ラインでお気軽に見学予約ができます
              </Text>
            </Box>
            <Box
              bg={`blackAlpha.400`}
              px={4}
              pt={3}
              pb={6}
            >
              <Heading
                fontSize={18}
              >
                便利な使い方❸
              </Heading>
              <Text
                mt={4}
                fontSize={14}
              >
                その他、お困りごともラインにてご相談ください
              </Text>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Layout>
  )
}

export default LineFriend

